import Image from 'next/image';
import React from 'react';

import ComHRInnerList from '@/components/blocks/ComHRLanding/core/ComHROffers/InnerList';
import { APPLY_FOR_JOB_KEY } from '@/components/blocks/ComHRLanding/helpers/constants/menu';
import { Offer } from '@/components/blocks/ComHRLanding/types/offers';
import HRButton from '@/components/blocks/HRLanding/HRButton';
import { ScrollLink } from '@/components/UI/ScrollLink';

import cn from './style.module.sass';

type ComHROffersCardProps = Offer;

function ComHROffersCard({ id, icon, title, list, buttonValue }: ComHROffersCardProps) {
    return (
        <div className={cn.offersCard} id={id}>
            <Image className={cn.offersCard__image} src={icon} alt="" width={400} height={400} aria-hidden="true" />
            <div className={cn.offersCard__info}>
                <div className={cn.offersCard__title} dangerouslySetInnerHTML={{ __html: title }} />
                <div className={cn.offersCardList}>
                    <ComHRInnerList list={list} />
                </div>
                <ScrollLink
                    to={APPLY_FOR_JOB_KEY}
                    offset={-90}
                    activeClass="menuLinkActive"
                    className="productPanelMenuLink"
                >
                    <HRButton isDark customClass={cn.offersCard__button} type="button" label={buttonValue} />
                </ScrollLink>
            </div>
        </div>
    );
}

export default ComHROffersCard;
