import clsx from 'clsx';
import { useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import Star from '@/components/blocks/ComHRLanding/core/Star';
import { vacancyOptions } from '@/components/blocks/ComHRLanding/data/form';
import HRButton from '@/components/blocks/HRLanding/HRButton';
import Checkbox from '@/components/UI/Checkbox';
import CustomSelect from '@/components/UI/CustomSelect';
import { FileUpload } from '@/components/UI/FileUpload';
import Input from '@/components/UI/Input';
import Title from '@/components/UI/Title';
import { InnerFormValues, useValidationSchema } from '@/hooks/useValidationSchema';
import { IBXResponseForms } from '@/types/requests';
import { ISelectElement } from '@/types/select';
import { getFormDataFromHookForm } from '@/utils/forms/getFormDataFromHookForm';
import { getFormErrors } from '@/utils/forms/getFormErrors';
import { GTagEvent, universalEvent } from '@/components/shared/utilities/analytics/metrics';
import { removePropertyFromObject } from '@/utils/removePropertyFromObject';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

type FormProps = {
    title: string;
    buttonSubmitValue: string;
    _template: 'comHRLandingForm';
    defaultValue?: string;
    hrGtag?: {
        [key: string]: GTagEvent;
    };
};

function Form({ title, buttonSubmitValue, defaultValue, hrGtag, _template = 'comHRLandingForm' }: FormProps) {
    const searchParams = useSearchParams();

    const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
    const [isFail, setIsFail] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [currentVacancy, setCurrentVacancy] = useState<ISelectElement<string> | undefined>(
        () => vacancyOptions.find(item => item.value === defaultValue) || undefined
    );

    const formResolver = useValidationSchema([
        { name: 'fio', type: 'fio', required: true },
        { name: 'phone', type: 'phone', required: true },
        { name: 'email', type: 'email', required: true },
        { name: 'accept', type: 'checkbox', required: true },
        { name: 'file', type: 'arrayOfStringsOptional', required: false },
    ]);

    const methods = useForm<InnerFormValues>({
        resolver: formResolver,
    });

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid },
    } = useForm<InnerFormValues>({ resolver: formResolver, mode: 'onChange' });

    // Регистрация полей для файла, обязательно, так как ref занят useDropzone src/components/blocks/HRLanding/HRLandingForm/index.tsx
    useEffect(() => {
        register('file');
    }, [register]);

    const fileTypes = ['.doc', '.pdf'];

    const onSubmit = async finalData => {
        setIsFormSubmitting(true);

        const utmSource = searchParams.get('utm_source') || undefined;
        const utmMedium = searchParams.get('utm_medium') || undefined;
        const utmCampaing = searchParams.get('utm_campaing') || undefined;
        const utmTerm = searchParams.get('utm_term') || undefined;
        const pagePath = typeof window !== undefined ? window.location.href : undefined;

        const filteredFinalData = removePropertyFromObject({ vacancy: currentVacancy.value, ...finalData }, 'accept');
        const formData = getFormDataFromHookForm({
            ...filteredFinalData,
            pagePath,
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaing: utmCampaing,
            utm_term: utmTerm,
        });

        try {
            const response = await apiRequest.post<IBXResponseForms<{ message: string }>>(
                `/forms/${_template}/send`,
                formData
            );

            if (response?.data?.errors.length > 0) {
                setIsFail(true);

                throw new Error(response?.data?.errors[0].message);
            } else {
                setIsSuccess(true);

                if (hrGtag) {
                    universalEvent(hrGtag.gtagForm);
                }
            }
        } catch (error) {
            console.error(error);
        }

        setIsFormSubmitting(false);
    };

    const [isErrorFio, errorFioText] = getFormErrors(errors, 'fio');
    const [isErrorPhone, errorPhoneText] = getFormErrors(errors, 'phone');
    const [isErrorEmail, errorEmailText] = getFormErrors(errors, 'email');
    const [isErrorAccept, errorAcceptText] = getFormErrors(errors, 'accept');

    const disableSubmitButton = !isValid || isFormSubmitting;

    return (
        <FormProvider {...methods}>
            <div className={cn.form}>
                <div className={cn.form__container}>
                    <div className={cn.form__title}>{title}</div>
                    {!isFail && !isSuccess && (
                        <form className={cn.form__info} onSubmit={handleSubmit(onSubmit)} noValidate>
                            <div className={clsx(cn.form__field, cn.form__field_start)}>
                                <label className={cn.form__label} htmlFor="vacancy">
                                    Вакансия <Star />
                                </label>
                                <CustomSelect
                                    isSearchable={false}
                                    value={vacancyOptions.find(item => item.value === currentVacancy?.value)}
                                    onChange={(e: ISelectElement<string>) => {
                                        setCurrentVacancy(vacancyOptions.find(item => item.value === e.value));
                                    }}
                                    customClass={cn.selectVacancy}
                                    options={vacancyOptions}
                                    placeholder="Выберите"
                                />
                            </div>
                            <div className={clsx(cn.form__field, cn.form__field_center)}>
                                <label className={cn.form__label} htmlFor="fio">
                                    ФИО <Star />
                                </label>
                                <div>
                                    <Input
                                        shouldColored
                                        type="text"
                                        placeholder="Введите ФИО"
                                        customClass={cn.customField}
                                        isError={isErrorFio}
                                        errorText={errorFioText}
                                        {...register('fio')}
                                    />
                                </div>
                            </div>
                            <div className={clsx(cn.form__field, cn.form__field_center)}>
                                <label className={cn.form__label} htmlFor="phone">
                                    Телефон <Star />
                                </label>
                                <div>
                                    <Input
                                        type="tel"
                                        placeholder="Введите номер"
                                        mask="+7 (999) 999-99-99"
                                        customClass={cn.customField}
                                        isError={isErrorPhone}
                                        errorText={errorPhoneText}
                                        {...register('phone')}
                                    />
                                </div>
                            </div>
                            <div className={clsx(cn.form__field, cn.form__field_center)}>
                                <label className={cn.form__label} htmlFor="email">
                                    E-mail <Star />
                                </label>
                                <div>
                                    <Input
                                        shouldColored
                                        errorText={errorEmailText}
                                        customClass={cn.customField}
                                        isError={isErrorEmail}
                                        type="text"
                                        placeholder="Введите e-mail"
                                        {...register('email')}
                                    />
                                </div>
                            </div>
                            <div className={clsx(cn.form__field, cn.form__field_start)}>
                                <label className={clsx(cn.form__label, cn.form__label__indent_vertical)} htmlFor="file">
                                    Добавить резюме
                                </label>
                                <FileUpload
                                    name="file"
                                    onFileChange={(files: Array<File>) => {
                                        setValue('file', files);
                                    }}
                                    label="Приложить файл"
                                    fileTypes={fileTypes}
                                    type="secondary"
                                />
                            </div>
                            <Checkbox
                                customClass={cn.form__checkboxWrapper}
                                classCheckmark={cn.form__checkbox}
                                size="small"
                                isError={isErrorAccept}
                                errorText={errorAcceptText}
                                label='Я даю своё согласие на <a href="https://www.pochtabank.ru/personal-data?_ga=2.169039207.773343870.1681377168-720124909.1675855140">обработку персональных данных</a>'
                                {...register('accept')}
                            />
                            <HRButton
                                isBg
                                buttonType="submit"
                                customClass={cn.form__buttonSubmit}
                                label={buttonSubmitValue}
                                disable={disableSubmitButton}
                                type="button"
                            />
                        </form>
                    )}
                    {isFail && (
                        <Title
                            title="К сожалению, произошла ошибка при отправке формы. Попробуйте позднее."
                            level={4}
                        />
                    )}
                    {isSuccess && <Title title="Спасибо мы с вами свяжемся!" level={4} />}
                </div>
            </div>
        </FormProvider>
    );
}

export default Form;
