import React from 'react';

import Ellipse from '@/components/blocks/ComHRLanding/core/Icons/Ellipse';
import Polygon from '@/components/blocks/ComHRLanding/core/Icons/Polygon';
import Star from '@/components/blocks/ComHRLanding/core/Icons/Star';
import { SALES_OFFICES_KEY, WORKING_WITH_CLIENTS_KEY } from '@/components/blocks/ComHRLanding/helpers/constants/menu';

export const offers = [
    {
        id: SALES_OFFICES_KEY,
        icon: '/img-next/jpg/hr-landing/offers/offer-1.jpg',
        title: 'Работа в&nbsp;офисах продаж',
        buttonValue: 'Хочу в команду',
        list: {
            title: 'Вы будете:',
            items: [
                {
                    icon: <Ellipse fill="#504fd7" />,
                    name: 'Работать с&nbsp;клиентами в&nbsp;офисах продаж или на&nbsp;территории магазина-партнера',
                },
                {
                    icon: <Polygon fill="#504fd7" />,
                    name: 'Консультировать клиентов и&nbsp;подбирать продукты банка',
                },
                {
                    icon: <Star fill="#504fd7" />,
                    name: 'Оформлять необходимые документы',
                },
            ],
        },
    },
    {
        id: WORKING_WITH_CLIENTS_KEY,
        icon: '/img-next/jpg/hr-landing/offers/offer-2.jpg',
        title: 'Работа с&nbsp;клиентами в&nbsp;чатах&nbsp;и&nbsp;на&nbsp;звонках',
        buttonValue: 'Хочу в команду',
        list: {
            title: 'Вы будете:',
            items: [
                {
                    icon: <Star fill="#d52679" />,
                    name: 'Консультировать клиентов по&nbsp;текущим продуктам и&nbsp;услугам, без продаж',
                },
                {
                    icon: <Polygon fill="#d52679" />,
                    name: 'Решать вопросы клиентов на&nbsp;звонке или в&nbsp;чате',
                },
            ],
        },
    },
];
