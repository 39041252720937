import {
    DO_WE_OFFER_KEY,
    SALES_OFFICES_KEY,
    WORKING_WITH_CLIENTS_KEY,
} from '@/components/blocks/ComHRLanding/helpers/constants/menu';

export const menu = [
    { title: 'Что мы предлагаем', id: DO_WE_OFFER_KEY },
    { title: 'Работа в офисах продаж', id: SALES_OFFICES_KEY },
    { title: 'Работа с клиентами в чатах и на звонках', id: WORKING_WITH_CLIENTS_KEY },
];
