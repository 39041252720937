import clsx from 'clsx';
import React, { useRef } from 'react';

import CrossIcon from '@/components/blocks/ComHRLanding/core/Icons/Cross';
import Menu, { TMenu } from '@/components/blocks/ComHRLanding/core/Menu';
import { APPLY_FOR_JOB_KEY } from '@/components/blocks/ComHRLanding/helpers/constants/menu';
import HRButton from '@/components/blocks/HRLanding/HRButton';
import { ScrollLink } from '@/components/UI/ScrollLink';
import useOnClickOutside from '@/hooks/useOnClickOutside';

import cn from './style.module.sass';

type MobileMenuProps = {
    menu: TMenu['list'];
    btnLabel: string;
    closeMenu: () => void;

    active?: boolean;
};

function MobileMenu({ closeMenu, active, menu, btnLabel }: MobileMenuProps) {
    const ref = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(ref, closeMenu);

    const handleClick = () => {
        closeMenu();
    };

    return (
        <div
            className={clsx(cn.menu, {
                [cn.menu_active]: active,
            })}
            ref={ref}
        >
            <div className={cn.menu__top}>
                <Menu closeMenu={closeMenu} list={menu} color="black" />
                <ScrollLink
                    to={APPLY_FOR_JOB_KEY}
                    offset={-90}
                    activeClass="menuLinkActive"
                    className="productPanelMenuLink"
                    onClick={handleClick}
                >
                    <HRButton isDark customClass={cn.menu__button} type="button" label={btnLabel} />
                </ScrollLink>

                <button className={cn.menu__cross} onClick={closeMenu} type="button">
                    <CrossIcon stroke="#1B1A1C" />
                </button>
            </div>
        </div>
    );
}

export default MobileMenu;
