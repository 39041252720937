import clsx from 'clsx';
import React, { useState } from 'react';

import Container from '@/components/blocks/ComHRLanding/core/Container';
import Menu, { TMenu } from '@/components/blocks/ComHRLanding/core/Menu';
import Socials from '@/components/blocks/ComHRLanding/core/Socials';
import { APPLY_FOR_JOB_KEY } from '@/components/blocks/ComHRLanding/helpers/constants/menu';
import { disclaimer } from '@/components/blocks/HRLanding/data/disclaimer';
import { socialHR } from '@/components/blocks/HRLanding/data/social';
import HRButton from '@/components/blocks/HRLanding/HRButton';
import CustomImage from '@/components/CustomImage';
import SubscriptionModal from '@/components/SubscriptionModal';
import { ScrollLink } from '@/components/UI/ScrollLink';

import cn from './style.module.sass';

type Props = {
    menu: TMenu['list'];
};

function Footer({ menu }: Props) {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    return (
        <div className={cn.footer}>
            <Container>
                <div className={cn.footer__grid}>
                    <div className={clsx(cn.footer__item, cn.footer__item_logo)}>
                        <a href="/">
                            <CustomImage
                                src="/img-next/svg/hr-landing/logo-footer.svg"
                                alt="Логотип"
                                width="97"
                                height="50"
                            />
                        </a>
                    </div>
                    <div className={clsx(cn.footer__item, cn.footer__item_menu)}>
                        <Menu align="center" list={menu} color="gray" />
                    </div>
                    <div className={clsx(cn.footer__item, cn.footer__item_socials)}>
                        <Socials socials={socialHR} />
                    </div>
                    <div className={clsx(cn.footer__item, cn.footer__item_button)}>
                        <ScrollLink
                            to={APPLY_FOR_JOB_KEY}
                            offset={-90}
                            activeClass="menuLinkActive"
                            className="productPanelMenuLink"
                        >
                            <HRButton customClass={cn.footer__button} type="button" label="Хочу в команду" />
                        </ScrollLink>
                    </div>
                    <div className={clsx(cn.footer__item, cn.footer__item_info)}>
                        <button
                            className={cn.footer__button_info}
                            type="button"
                            onClick={() => {
                                setIsModalOpen(true);
                            }}
                        >
                            Информация об изображениях, использованных на сайте.
                        </button>
                    </div>
                </div>
            </Container>
            <SubscriptionModal
                isOpen={isModalOpen}
                isFail
                isInfoText
                handleClose={() => {
                    setIsModalOpen(false);
                }}
                title={disclaimer}
            />
        </div>
    );
}

export default Footer;
