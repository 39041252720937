import clsx from 'clsx';
import React from 'react';

import { ScrollLink } from '@/components/UI/ScrollLink';

import cn from './style.module.sass';

type TMenuItem = {
    title: string;
    id?: string;
};

export type TMenu = {
    list: Array<TMenuItem>;
    color?: 'gray' | 'black';
    align?: 'center' | 'left';
    closeMenu?: () => void;
};

function Menu({ align, list, closeMenu, color = 'black' }: TMenu) {
    const handleClick = () => {
        if (closeMenu) {
            closeMenu();
        }
    };

    return (
        <div
            className={clsx(cn.menu, {
                [cn.menu_center]: align === 'center',
                [cn.menu_left]: align === 'left',
            })}
        >
            {list?.map(({ title, id }) => (
                <ScrollLink
                    className={clsx(cn.menu__item, {
                        [cn.menu__item_black]: color === 'black',
                        [cn.menu__item_gray]: color === 'gray',
                    })}
                    to={id}
                    key={id}
                    onClick={handleClick}
                    offset={-90}
                >
                    {title}
                </ScrollLink>
            ))}
        </div>
    );
}

export default Menu;
