import React from 'react';

import { Icon } from '@/components/blocks/ComHRLanding/types/icon';

function Star({ fill = 'none' }: Icon) {
    return (
        <svg width="16" height="17" fill={fill}>
            <path d="m8 .146 2.602 5.398L16 8.146l-5.398 2.603L8 16.146 5.398 10.75 0 8.146l5.398-2.602L8 .146Z" />
        </svg>
    );
}

export default Star;
