import React from 'react';

import ComHRAdvantagesCard from '@/components/blocks/ComHRLanding/core/ComHRAdvantages/Card';
import { Advantages } from '@/components/blocks/ComHRLanding/types/advantages';

import cn from './style.module.sass';

type ComHRAdvantagesListProps = {
    list: Advantages['list'];
};

function ComHRAdvantagesList({ list }: ComHRAdvantagesListProps) {
    if (list.length === 0) return null;

    return (
        <div className={cn.advantagesList}>
            {list.map(({ icon, title }) => (
                <div className={cn.advantagesList__item} key={title}>
                    <ComHRAdvantagesCard icon={icon} title={title} />
                </div>
            ))}
        </div>
    );
}

export default ComHRAdvantagesList;
