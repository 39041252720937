import React, { useState } from 'react';

import BurgerIcon from '@/components/blocks/ComHRLanding/core/Icons/Burger';
import Menu, { TMenu } from '@/components/blocks/ComHRLanding/core/Menu';
import MobileMenu from '@/components/blocks/ComHRLanding/core/MobileMenu';
import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

type Props = {
    menu: TMenu['list'];
};

function Header({ menu }: Props) {
    const [isMenuShown, setIsMenuShow] = useState<boolean>(false);

    const showMenu = () => {
        setIsMenuShow(true);
    };

    const hideMenu = () => {
        setIsMenuShow(false);
    };

    return (
        <div className={cn.header}>
            <div className={cn.header__wrapper}>
                <a href="/" className={cn.header__logo}>
                    <CustomImage
                        src="/img-next/svg/hr-landing/logo-default.svg"
                        alt="Логотип"
                        width="110"
                        height="52"
                    />
                </a>
                <div className={cn.header__menu}>
                    <Menu list={menu} color="black" />
                </div>
                <button className={cn.header__button_menu} onClick={showMenu} type="button">
                    <BurgerIcon fill="#1B1A1C" />
                </button>
            </div>
            <MobileMenu btnLabel="Хочу в команду" active={isMenuShown} closeMenu={hideMenu} menu={menu} />
        </div>
    );
}

export default Header;
