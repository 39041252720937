export const promo = {
    title: 'Почта Банк.<br>Самый большой&nbsp;банк страны',
    description: 'Больше, чем новая работа. Новые возможности.',
    buttonValue: 'Хочу в команду',
    slides: [
        {
            src: '/img-next/png/hr-landing/promo/promo-1.png',
            rotate: -3.11,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-2.png',
            rotate: 3.87,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-3.png',
            rotate: -0.46,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-4.png',
            rotate: -1.44,
        },
        {
            src: '/img-next/png/hr-landing/promo/promo-5.png',
            rotate: 0,
        },
    ],
};
