import { ISelectElement } from '@/types/select';

export const form = {
    title: 'Откликнуться на вакансию',
    buttonValue: 'Отправить отклик',
};

export const vacancyOptions: Array<ISelectElement<string>> = [
    {
        value: 'Работа в офисах продаж',
        label: 'Работа в офисах продаж',
    },
    {
        value: 'Работа с клиентами в чатах и на звонках',
        label: 'Работа с клиентами в чатах и на звонках',
    },
];
