import React, { Suspense } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import ComHRAdvantages from '@/components/blocks/ComHRLanding/core/ComHRAdvantages';
import ComHROffers from '@/components/blocks/ComHRLanding/core/ComHROffers';
import ComHRPromo from '@/components/blocks/ComHRLanding/core/ComHRPromo';
import Container from '@/components/blocks/ComHRLanding/core/Container';
import Footer from '@/components/blocks/ComHRLanding/core/Footer';
import Form from '@/components/blocks/ComHRLanding/core/Form';
import Header from '@/components/blocks/ComHRLanding/core/Header';
import { advantages } from '@/components/blocks/ComHRLanding/data/advantages';
import { hrGtag } from '@/components/blocks/ComHRLanding/data/hrGtag';
import { menu } from '@/components/blocks/ComHRLanding/data/menu';
import { offers } from '@/components/blocks/ComHRLanding/data/offers';
import { promo } from '@/components/blocks/ComHRLanding/data/promo';
import { APPLY_FOR_JOB_KEY, DO_WE_OFFER_KEY } from '@/components/blocks/ComHRLanding/helpers/constants/menu';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Feature from '@/domain/feature/Feature';
import { InnerFormValues } from '@/hooks/useValidationSchema';

export interface IComHRLanding {
    _template?: 'comHRLandingForm';
}

function ComHRLanding({ _template }: IComHRLanding) {
    const methods = useForm<InnerFormValues>();

    if (!Feature.isFeatureEnabled('enableComHRLanding')) return null;

    return (
        <FormProvider {...methods}>
            <div>
                <Container>
                    <Header menu={menu} />
                </Container>
                <Container>
                    <ComHRPromo
                        title={promo.title}
                        description={promo.description}
                        buttonValue={promo.buttonValue}
                        slides={promo.slides}
                    />
                </Container>
                <Container id={DO_WE_OFFER_KEY}>
                    <ComHRAdvantages title={advantages.title} list={advantages.list} />
                </Container>
                <Container>
                    <ComHROffers offers={offers} />
                </Container>
                <Container id={APPLY_FOR_JOB_KEY}>
                    <Suspense>
                        <Form
                            title="Откликнуться на вакансию"
                            buttonSubmitValue="Отправить отклик"
                            _template="comHRLandingForm"
                            hrGtag={hrGtag}
                        />
                    </Suspense>
                </Container>
                <Footer menu={menu} />
            </div>
        </FormProvider>
    );
}

export default withBlockLinks(ComHRLanding);
