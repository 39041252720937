import React from 'react';

import ComHROffersList from '@/components/blocks/ComHRLanding/core/ComHROffers/List';
import { Offers } from '@/components/blocks/ComHRLanding/types/offers';

type ComHROffersProps = {
    offers: Offers;
};

function ComHROffers({ offers }: ComHROffersProps) {
    return <ComHROffersList offers={offers} />;
}

export default ComHROffers;
