import React from 'react';

import ComHROffersCard from '@/components/blocks/ComHRLanding/core/ComHROffers/Card';
import { Offers } from '@/components/blocks/ComHRLanding/types/offers';

import cn from './style.module.sass';

type ComHROffersListProps = {
    offers: Offers;
};

function ComHROffersList({ offers }: ComHROffersListProps) {
    if (offers.length === 0) return null;

    return (
        <div className={cn.offersList}>
            {offers.map(({ id, icon, title, list, buttonValue }) => (
                <ComHROffersCard icon={icon} title={title} list={list} buttonValue={buttonValue} id={id} key={title} />
            ))}
        </div>
    );
}

export default ComHROffersList;
